.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 50px;
  --ReactInputVerificationCode-itemHeight: 50px;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #283E76;
  font-weight: bold;
  background-color: #fff;
  border: 1px solid #283E7619;
  border-radius: 0;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: #fff;
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: #046cde;
}

.custom-styles .ReactInputVerificationCode__container {
  width: 100%;
}

.mention_input_wrapper textarea {
  overflow: visible !important;
  outline: none !important;
  color: #001D67;
  padding: 4px 8px;
  line-height: 20px;
  border: 1px solid #0000003b;
  font-size: 0.875rem !important;
}

.mention_input_wrapper .mention_input_wrapper__control {
  min-height: 30px;
  max-height: 68px;
}

.is_dark_mode .mention_input_wrapper textarea {
  color: #fff;
}

.mention_input_wrapper__suggestions {
  padding: 3px 0;
  margin-top: 32px !important;
  border: 1px solid #0000003b;
}

.is_dark_mode .mention_input_wrapper__suggestions {
  background: #101e41 !important;
}

.mention_input_wrapper__suggestions__item {
  padding: 0 10px;
}

.apexcharts-legend-marker {
  vertical-align: top;
}

.apexcharts-legend-text {
  vertical-align: top;
}

.MuiTooltip-tooltip {
  background: #001D67CC !important;
}

.btn_country_code.is_dark > div {
  background-color: #101e41 !important;
}

.search_country_code.is_dark {
  background-color: #101e41 !important;
}

.btn_country_code.is_dark .country:hover, .btn_country_code.is_dark .country.highlight {
  color: #101e41;
}

@media (max-width: 480px) {
  .custom-styles {
      --ReactInputVerificationCode-itemWidth: 38px;
      --ReactInputVerificationCode-itemHeight: 38px;
  }
}

@media print {
  .printing .app_bar_wrapper {
    display: none;
  }
  
  .printing .drawer_wrapper {
    display: none;
  }

  .printing .header_toolbar_wrapper {
    display: none;
  }

  .printing .body_wrapper {
    padding: 0;
  }

  .printing .sub_menu_wrapper {
    display: none;
  }

  .printing .breadcrumb_wrapper {
    display: none;
  }

  .printing .main_content_wrapper {
    max-width: 100%;
    flex-basis: 100%;
  }

  .printing .MuiDataGrid-columnHeaderTitle {
    font-size: 11px;
  }

  .printing .MuiDataGrid-cell p {
    font-size: 11px;
  }

  body, html {
    width: 100%;
  }

  .printing .print_no_display {
    display: none;
  }

  .printing .main_content_body {
    padding: 0;
  }

  .printing .report_background_wrapper {
    padding: 0;
  }
}